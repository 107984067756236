import { Directorio } from "@/interfaces/Directorio";
import { AxiosResponse } from "axios";
import axios from "./axios";

export const getDirectorios = async (): Promise<AxiosResponse<Directorio[]>> =>
  await axios.get("/directorios/get");

export const getDirectorio = async (id: string): Promise<AxiosResponse<Directorio>> =>
  await axios.get(`/directorios/GetById/${id}`);

  export const getOneDirectorio = async (): Promise<AxiosResponse<Directorio>> =>
  await axios.get("/directorios/GetOne");

export const createDirectorio = async (directorio: Directorio): Promise<AxiosResponse> =>
  await axios.post("/directorios/Save", directorio);

export const createDirectorioa = async (directorio: Directorio): Promise<AxiosResponse> =>
  await axios.post("/directorios/Savea", directorio);

  export const eliminateDirectorios = async (directorio: Directorio): Promise<AxiosResponse> =>
  await axios.post("/directorios/delete", directorio);

export const updateDirectorio = async (
  id: string,
  newDirectorio: Directorio
): Promise<AxiosResponse<Directorio>> => await axios.put(`/directorios/Update/${id}`, newDirectorio);

export const deleteDirectorio = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/directorios/Delete/${id}`);
