
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Documento } from "@/interfaces/Documento";
import { Medico } from "@/interfaces/Medico";
import { getMedicos, updateMedico } from "@/services/MedicoService";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";
import {
  deleteDocumento,
  getDocumento,
  updateDocumento,
} from "@/services/DocumentoService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "documento-list",
  components: {
    Navbar,
  },
  data() {
    return {
      cargando: false,
      clienteSelected: [],
      medicoSelected: [],
      documento: {} as Documento,
      estado: {} as object,
      medicos: [] as Medico[],
      clientes: [] as Cliente[],
    };
  },
  methods: {
    async facturar() {
      this.documento.cantPrefactura = 0;
      this.documento.cantFactura = 1;
      this.documento.credito = 0;
      this.documento.debito = 0;
      this.documento.subTipo = "Factura";
      this.documento.factura = this.documento.prefactura;
      this.documento.prefactura = 0;
      await this.selectCliente(this.documento.descCliente);
      this.documento.literal =
        this.clienteSelected[0].tipoComprobante.substring(0, 3);
      await this.selectMedico(this.documento.medico);
      if (this.documento.literal == "B15") {
        this.documento.ncf = this.medicoSelected[0].compGuberAsig;
        this.documento.vencimiento = this.medicoSelected[0].fechGuberAsig;
        this.documento.fechaNCF = new Date();
      } else {
        this.documento.ncf = this.medicoSelected[0].compNoGuberAsig;
        this.documento.vencimiento = this.medicoSelected[0].fechNoGuberAsig;
        this.documento.fechaNCF = new Date();
      }
      this.handleUpdate();
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    selectCliente(term: string) {
      this.clienteSelected = this.clientes.filter((cliente: Cliente) => {
        return cliente.descripcion.toLowerCase().includes(term.toLowerCase());
      });
      this.documento.rncCliente = this.clienteSelected[0].rnc;
      this.documento.razonCliente = this.clienteSelected[0].razonSocial;
    },
    selectMedico(term: string) {
      this.medicoSelected = this.medicos.filter((medico: Medico) => {
        return medico.emisor.toLowerCase().includes(term.toLowerCase());
      });
      this.documento.rncMedico = this.medicoSelected[0].rncEmisor;
      this.documento.direccion = this.medicoSelected[0].direccionEmisor;
    },

    async loadClientes() {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fixTime() {
      this.documento.fechaProceso = this.formatDate(
        this.documento.fechaProceso
      );
      this.documento.fechaEfectivo = this.formatDate(
        this.documento.fechaEfectivo
      );
      this.documento.fechaVence = this.formatDate(this.documento.fechaVence);
    },

    async loadDocumento(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getDocumento(id);
        this.documento = data;
        this.estado = this.documento.estado;
        this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },
    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.documento.userMod = this.$store.state.user.usuario;
          await updateDocumento(this.$route.params.id, this.documento);
          if (this.documento.literal == "B15") {
            this.medicoSelected[0].compGuberAsig += 1;
          } else {
            this.medicoSelected[0].compNoGuberAsig += 1;
          }
          await updateMedico(
            this.medicoSelected[0]._id,
            this.medicoSelected[0]
          );
          this.$router.push("/documentos");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteDocumento(this.$route.params.id);
          this.$router.push("/documentos");
        }
      } catch (error) {
        //console.error(error);
      }
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
  },
  mounted() {
    this.loadMedicos();
    this.loadClientes();
    if (typeof this.$route.params.id === "string") {
      this.loadDocumento(this.$route.params.id);
    }
  },
});
