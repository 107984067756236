
import { registerUsuario } from "@/services/UsuarioService";
import { Usuario } from "@/interfaces/Usuario";
export default {
  name: "LoginView",
  components: {},

  data() {
    return {
      cargando: false,
      error: "",
      usuario: {} as Usuario,
      respuesta: {},
      resp: {},
    };
  },

  methods: {
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async signup() {
      this.toggleLoading();
      const resp = await registerUsuario(this.usuario).then(
        (res) => {
          this.error = "";
          this.$router.push("/");
          this.resp = resp;
          this.respuesta = res;
        },
        (err) => {
          // console.log(err.response);
          this.error = err.response.data.error;
        }
      );
      this.toggleLoading();
    },

    // login() {
    //   this.$store.dispatch("loginAction");
    //   this.$store.state.user.type = this.role;
    //   this.$router.push("/dashboard");
    // },
  },
};
