
import Navbar from "@/components/Navbar.vue";
import { Medico } from "@/interfaces/Medico";
import { getRecibosRep } from "@/services/ReciboService";
import numeral from "numeral";
export default {
  name: "DashboardView",
  components: {
    Navbar,
  },

  data() {
    return {
      cargando: false,
      medicos: [] as Medico[],
      totales: {
        recibos: 0,
        bruto: 0,
        retension: 0,
        neto: 0,
      },
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      recibos: [] as any,
    };
  },

  methods: {
    valorTotal() {
      this.totales.recibos = this.recibos.reduce(
        (accum: any, item: any) => accum + item.count,
        0
      );
      this.totales.bruto = this.recibos.reduce(
        (accum: any, item: any) => accum + item.bruto,
        0
      );
      this.totales.retension = this.recibos.reduce(
        (accum: any, item: any) => accum + item.retension,
        0
      );
      this.totales.neto = this.recibos.reduce(
        (accum: any, item: any) => accum + item.neto,
        0
      );
    },
    // async loadMedicos() {
    //   try {
    //     const res = await getMedicos();
    //     this.medicos = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    // },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    async filterEstado(term: string) {
      this.cargando = !this.cargando;
      try {
        const res = await getRecibosRep();
        this.recibos = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.recibos = this.recibos.filter((recibo: any) => {
        return recibo._id.medico.toLowerCase().includes(term.toLowerCase());
      });
      this.valorTotal();
      this.cargando = !this.cargando;
    },
  },

  mounted() {
    this.filterEstado(this.$store.state.user.usuario);
    // this.loadMedicos();
  },
};
