
import numeral from "numeral";
import moment from "moment";

export default {
  props: ["recibos"],
  data() {
    return {
      data: false,
      totales: {
        recibos: 0,
        bruto: 0,
        retension: 0,
        neto: 0,
      },
    };
  },
  methods: {
    valorTotal() {
      this.totales.recibos = this.recibos.length;
      this.totales.bruto = this.recibos.reduce(
        (accum: any, item: any) => accum + item.bruto,
        0
      );
      this.totales.retension = this.recibos.reduce(
        (accum: any, item: any) => accum + item.retension,
        0
      );
      this.totales.neto = this.recibos.reduce(
        (accum: any, item: any) => accum + item.neto,
        0
      );
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("DD/MM/YYYY");
    },
  },

  updated() {
    this.valorTotal();
    // this.data = !this.data;
  },
};
