
import Navbar from"@/components/Navbar.vue";
import { defineComponent } from"vue";
import { Recepcion } from"@/interfaces/Recepcion";
import { createRecepcion, eliminateRecepcions, createRecepciona } from"@/services/RecepcionService";
import { getOneRecepcion } from "@/services/RecepcionService";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";

export default defineComponent({
  name:"recepcions-form",
  components: {
    Navbar,
  },  
  data() {
    return {
      recepcion: {} as Recepcion,
      oneRecepcion: {} as Recepcion,
      one: {},
      nextNo: Number,
      medicos: [] as Medico[],      
      
    };
  },
  
  mounted() {
    // this.loadOneRecepcion();
    this.loadMedicos();
  },

  methods: {
    async loadOneRecepcion() {
      try {
        const res = await getOneRecepcion();
        this.oneRecepcion = res.data;
        this.one = this.oneRecepcion[0];
        this.nextNo = this.one.no + 1;
        this.recepcion.no = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveRecepciona() {
      try {
        const res = await createRecepciona(this.servicio);
        // console.log(res);
      } catch (error) {
        // console.error(error);
      }
    },

    async saveRecepcion() {
        try {
          try {
          const res = await getOneRecepcion();
          this.oneRecepcion = res.data;
          this.one = this.oneRecepcion[0];
          this.nextNo = this.one.no + 1;
          this.recepcion.no = this.nextNo;
        } catch (error) {
          // console.error(error);
        }
          const res = await createRecepcion(this.recepcion);
          // console.log(res);
          this.definingFields();
          this.focus();
        } catch (error) {
          // console.error(error);
        }
    },

    async deleteAllRecepcions() {
      try {
        const res = await eliminateRecepcions(this.recepcion);
        // console.log(res);
      } catch (error) {
        // console.error(error);
      }
    },

    definingFields() {
            this.recepcion.no =""
            this.recepcion.fecha =""
            this.recepcion.medico =""
            this.recepcion.entregadoPor =""
            this.recepcion.recibidoPor =""
            this.recepcion.lugar =""
            this.recepcion.cantidad =""
            this.recepcion.observaciones
    },

    focus() {
      document.getElementById("fecha").focus();
    },

  },
});
