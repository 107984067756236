import { Cliente } from "@/interfaces/Cliente";
import { AxiosResponse } from "axios";
import axios from "./axios";

export const getClientes = async (): Promise<AxiosResponse<Cliente[]>> =>
  await axios.get("/clientes/get");

export const getClientesShort = async (): Promise<AxiosResponse<Cliente[]>> =>
  await axios.get("/clientes/getshort");

export const getClientescentro = async (): Promise<AxiosResponse<Cliente[]>> =>
  await axios.get("/clientes/getcentro");

export const getCliente = async (id: string): Promise<AxiosResponse<Cliente>> =>
  await axios.get(`/clientes/GetById/${id}`);

export const getOneCliente = async (): Promise<AxiosResponse<Cliente>> =>
  await axios.get("/clientes/GetOne");

export const createCliente = async (cliente: Cliente): Promise<AxiosResponse> =>
  await axios.post("/clientes/Save", cliente);

export const createClientea = async (
  cliente: Cliente
): Promise<AxiosResponse> => await axios.post("/clientes/Savea", cliente);

export const eliminateClientes = async (
  cliente: Cliente
): Promise<AxiosResponse> => await axios.post("/clientes/delete", cliente);

export const updateCliente = async (
  id: string,
  newCliente: Cliente
): Promise<AxiosResponse<Cliente>> =>
  await axios.put(`/clientes/Update/${id}`, newCliente);

export const deleteCliente = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/clientes/Delete/${id}`);
