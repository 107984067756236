
const filters = [
  'Todos',
  'DGII',
  'ARS',
  'Otros',
]

export default {
  props: [
    'filterEnlaces',
    'search',
    'filteredEnlaces',
  ],

  data () {
    return {
      filters,
      term: '',
      filters2: [] as []
    }
  },
}
