import { Recepcion } from "@/interfaces/Recepcion";
import { AxiosResponse } from "axios";
import axios from "./axios";

export const getRecepcions = async (): Promise<AxiosResponse<Recepcion[]>> =>
  await axios.get("/recepcions/get");

export const getRecepcion = async (id: string): Promise<AxiosResponse<Recepcion>> =>
  await axios.get(`/recepcions/GetById/${id}`);

  export const getOneRecepcion = async (): Promise<AxiosResponse<Recepcion>> =>
  await axios.get("/recepcions/GetOne");

export const createRecepcion = async (recepcion: Recepcion): Promise<AxiosResponse> =>
  await axios.post("/recepcions/Save", recepcion);

export const createRecepciona = async (recepcion: Recepcion): Promise<AxiosResponse> =>
  await axios.post("/recepcions/Savea", recepcion);

  export const eliminateRecepcions = async (recepcion: Recepcion): Promise<AxiosResponse> =>
  await axios.post("/recepcions/delete", recepcion);

export const updateRecepcion = async (
  id: string,
  newRecepcion: Recepcion
): Promise<AxiosResponse<Recepcion>> => await axios.put(`/recepcions/Update/${id}`, newRecepcion);

export const deleteRecepcion = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/recepcions/Delete/${id}`);
