
import { defineComponent } from "vue";
import { Recibo } from "@/interfaces/Recibo";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";
import {
  deleteRecibo,
  getRecibo,
  updateRecibo,
} from "@/services/ReciboService";
import numeral from "numeral";

export default defineComponent({
  name: "recibo-list",
  components: {
  },
  data() {
    return {
      currentRecibo: {} as Recibo,
      estado: {} as object,
      medicos: [] as Medico[],
      clientes: [] as Cliente[],
    };
  },
  methods: {
    async loadClientes() {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },
    async loadRecibo(id: string) {
      try {
        const { data } = await getRecibo(id);
        this.currentRecibo = data;
        this.estado = this.currentRecibo.estado;
      } catch (error) {
        //console.error(error);
      }
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateRecibo(this.$route.params.id, this.currentRecibo);
          this.$router.push("/recibos");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteRecibo(this.$route.params.id);
          this.$router.push("/recibos");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadRecibo(this.$route.params.id);
    }
  },
});
