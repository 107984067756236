import { Recibo } from "@/interfaces/Recibo";
import { AxiosResponse } from "axios";
import axios from "./axios";

export const getRecibos = async (): Promise<AxiosResponse<Recibo[]>> =>
  await axios.get("/recibos/get");

export const getRecibosRep = async (): Promise<AxiosResponse<Recibo[]>> =>
  await axios.get("/recibos/getrep");

export const getRecibosRep2 = async (): Promise<AxiosResponse<Recibo[]>> =>
  await axios.get("/recibos/getrep2");

export const getRecibosRep3 = async (): Promise<AxiosResponse<Recibo[]>> =>
  await axios.get("/recibos/getrep3");

export const getRecibo = async (id: string): Promise<AxiosResponse<Recibo>> =>
  await axios.get(`/recibos/GetById/${id}`);

export const getOneRecibo = async (): Promise<AxiosResponse<Recibo>> =>
  await axios.get("/recibos/GetOne");

export const createRecibo = async (recibo: Recibo): Promise<AxiosResponse> =>
  await axios.post("/recibos/Save", recibo);

export const createReciboa = async (recibo: Recibo): Promise<AxiosResponse> =>
  await axios.post("/recibos/Savea", recibo);

export const eliminateRecibos = async (recibo: Recibo): Promise<AxiosResponse> =>
  await axios.post("/recibos/delete", recibo);

export const updateRecibo = async (
  id: string,
  newRecibo: Recibo
): Promise<AxiosResponse<Recibo>> => await axios.put(`/recibos/Update/${id}`, newRecibo);

export const deleteRecibo = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/recibos/Delete/${id}`);
