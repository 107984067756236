
import { defineComponent } from "vue";
import { Cliente } from "@/interfaces/Cliente";
import { deleteCliente, getCliente, updateCliente } from "@/services/ClienteService";

export default defineComponent({
  name: "cliente-list",
  components: {
  },
  data() {
    return {
      currentCliente: {} as Cliente,
    };
  },
  methods: {
    async loadCliente(id: string) {
      try {
        const { data } = await getCliente(id);
        this.currentCliente = data;
      } catch (error) {
        //console.error(error);
      }
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateCliente(this.$route.params.id, this.currentCliente);
          alert("Cliente actualizado con éxito")
          this.$router.push("/clientes");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteCliente(this.$route.params.id);
          alert("Cliente eliminado con éxito")
          this.$router.push("/clientes");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadCliente(this.$route.params.id);
    }
  },
});
