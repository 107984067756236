
const filters = [
  'Todos',
  'ARS',
  'Centro',
]

export default {
  props: [
    'filterDirectorios',
    'search',
    'filteredDirectorios',
  ],

  data () {
    return {
      filters,
      term: '',
      filters2: [] as []
    }
  },
}
