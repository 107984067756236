import { Balance } from "@/interfaces/Balance";
import { AxiosResponse } from "axios";
import axios from "./axios";

export const getBalances = async (): Promise<AxiosResponse<Balance[]>> =>
  await axios.get("/balances/get");

export const getBalancesRep = async (): Promise<AxiosResponse<Balance[]>> =>
  await axios.get("/balances/getrep");

export const getBalancesRep2 = async (): Promise<AxiosResponse<Balance[]>> =>
  await axios.get("/balances/getrep2");

export const getBalancesRep3 = async (): Promise<AxiosResponse<Balance[]>> =>
  await axios.get("/balances/getrep3");

export const getBalance = async (id: string): Promise<AxiosResponse<Balance>> =>
  await axios.get(`/balances/GetById/${id}`);

export const getOneBalance = async (): Promise<AxiosResponse<Balance>> =>
  await axios.get("/balances/GetOne");

export const createBalance = async (balance: Balance): Promise<AxiosResponse> =>
  await axios.post("/balances/Save", balance);

export const createBalancea = async (balance: Balance): Promise<AxiosResponse> =>
  await axios.post("/balances/Savea", balance);

export const eliminateBalances = async (balance: Balance): Promise<AxiosResponse> =>
  await axios.post("/balances/delete", balance);

export const updateBalance = async (
  id: string,
  newBalance: Balance
): Promise<AxiosResponse<Balance>> => await axios.put(`/balances/Update/${id}`, newBalance);

export const deleteBalance = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/balances/Delete/${id}`);
