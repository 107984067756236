import { Enlace } from "@/interfaces/Enlace";
import { AxiosResponse } from "axios";
import axios from "./axios";

export const getEnlaces = async (): Promise<AxiosResponse<Enlace[]>> =>
  await axios.get("/enlaces/get");

export const getEnlace = async (id: string): Promise<AxiosResponse<Enlace>> =>
  await axios.get(`/enlaces/GetById/${id}`);

  export const getOneEnlace = async (): Promise<AxiosResponse<Enlace>> =>
  await axios.get("/enlaces/GetOne");

export const createEnlace = async (enlace: Enlace): Promise<AxiosResponse> =>
  await axios.post("/enlaces/Save", enlace);

export const createEnlacea = async (enlace: Enlace): Promise<AxiosResponse> =>
  await axios.post("/enlaces/Savea", enlace);

  export const eliminateEnlaces = async (enlace: Enlace): Promise<AxiosResponse> =>
  await axios.post("/enlaces/delete", enlace);

export const updateEnlace = async (
  id: string,
  newEnlace: Enlace
): Promise<AxiosResponse<Enlace>> => await axios.put(`/enlaces/Update/${id}`, newEnlace);

export const deleteEnlace = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/enlaces/Delete/${id}`);
