
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Factura } from "@/interfaces/Factura";
import {
  createFactura,
  eliminateFacturas,
  createFacturaa,
} from "@/services/FacturaService";
import { updateMedico } from "@/services/MedicoService";
import { getOneFactura } from "@/services/FacturaService";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";
import { createBalance } from "@/services/BalanceService";
import numeral from "numeral";

export default defineComponent({
  name: "facturas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      balance: {},
      factura: {} as Factura,
      oneFactura: {} as Factura,
      one: {},
      nextNo: Number,
      medicos: [] as Medico[],
      medicoSelected: [],
      clientes: [] as Cliente[],
      clienteSelected: [],
      descripcionCliente: "",
      // literal: "",
      ncfSelected: [],
      buscComp: "",
      compiDef: [],
      newComprobante: {
        compNoGuberAsig: 8,
      },
    };
  },

  mounted() {
    this.loadOneFactura();
    this.loadMedicos();
    this.loadClientes();
    this.defFechas();
    // this.selectComprobante();
    // this.selectNCF();
  },

  methods: {
    async handleUpdate() {
      alert('Factura Generada Exitosamente.')
      if (this.factura.literal == "B15") {
        this.medicoSelected[0].compGuberAsig += 1;
      } else {
        this.medicoSelected[0].compNoGuberAsig += 1;
      }
      this.medicoSelected[0].estado.facturado += this.factura.totalGeneral
      this.medicoSelected[0].estado.porCobrar += this.factura.totalGeneral
      await updateMedico(this.medicoSelected[0]._id, this.medicoSelected[0]);
      // await this.loadMedicos();
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    asignarNCF() {
      this.factura.ncf = "";
      this.factura.literal = this.clienteSelected[0].tipoComprobante.substring(
        0,
        3
      );

      if (this.factura.literal == "B15") {
        this.factura.ncf = this.medicoSelected[0].compGuberAsig;
        this.factura.vencimiento = this.medicoSelected[0].fechGuberAsig;
      } else {
        this.factura.ncf = this.medicoSelected[0].compNoGuberAsig;
        this.factura.vencimiento = this.medicoSelected[0].fechNoGuberAsig;
      }
    },

    defFechas() {
      this.factura.fechaFactura = new Date();
      this.factura.fechaEntrega = new Date();
    },

    selectMedico(term: string) {
      this.medicoSelected = this.medicos.filter((medico: Medico) => {
        return medico.emisor.toLowerCase().includes(term.toLowerCase());
      });

      this.factura.direccionEmisor = this.medicoSelected[0].direccionEmisor;

      this.factura.rncEmisor = this.medicoSelected[0].rncEmisor;

      this.asignarNCF();

    },

    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    selectCliente(term: string) {
      this.clienteSelected = this.clientes.filter((cliente: Cliente) => {
        return cliente.descripcion.toLowerCase().includes(term.toLowerCase());
      });
      this.factura.rncReceptor = this.clienteSelected[0].rnc;
      this.factura.nombreRazonSocialReceptor =
        this.clienteSelected[0].razonSocial;
      // this.buscComp = this.clienteSelected[0].tipoComprobante;
      this.asignarNCF();
    },
    async loadClientes() {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadOneFactura() {
      try {
        const res = await getOneFactura();
        this.oneFactura = res.data;
        this.one = this.oneFactura[0];
        this.nextNo = this.one.no + 1;
        this.factura.no = this.nextNo;
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveFacturaa() {
      try {
        const res = await createFacturaa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveFactura() {
      this.handleUpdate();

      // this.selectMedico(this.factura.emisor);
      try {
        try {
          const res = await getOneFactura();
          this.oneFactura = res.data;
          this.one = this.oneFactura[0];
          this.nextNo = this.one.no + 1;
          this.factura.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.factura.userReg = this.$store.state.user.usuario;
        // Cargar Balance----------------------------------------
        this.balance.medico = this.factura.emisor;
        this.balance.cliente = this.factura.descripcionCliente;
        this.balance.fecha = this.factura.fechaFactura;
        this.balance.facturado = this.factura.totalGeneral;
        this.balance.facturas = 1;
        this.balance.pagado = 0;
        this.balance.recibos = 0;
        this.balance.balance = this.factura.totalGeneral;
        this.balance.documentos = 1;
        // -------------------------------------------------------
        const res2 = await createBalance(this.balance);        
        const res = await createFactura(this.factura);
        // // console.log(res);
        this.$router.push("/facturas/");
        // this.definingFields();
        // this.focus();
      } catch (error) {
        // // console.error(error);
      }
    },

    async deleteAllFacturas() {
      try {
        const res = await eliminateFacturas(this.factura);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      // this.factura.emisor = "";
      // this.factura.direccionEmisor = "";
      // this.factura.rncEmisor = "";
      // this.factura.fechaFactura = "";
      // this.factura.fechaEntrega = "";
      this.factura.ncf = "";
      this.factura.vencimiento = "";
      this.factura.rncReceptor = "";
      this.factura.nombreRazonSocialReceptor = "";
      this.factura.totalGeneral = "";
      this.descripcionCliente = "";
    },

    focus() {
      document.getElementById("emisor").focus();
    },
  },
});
