
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Recepcion } from "@/interfaces/Recepcion";
import { deleteRecepcion, getRecepcion, updateRecepcion } from "@/services/RecepcionService";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";

export default defineComponent({
  name: "recepcion-list",
  components: {
    Navbar,
  },
  data() {
    return {
      currentRecepcion: {} as Recepcion,
      medicos: [] as Medico[],
    };
  },
  methods: {
    async loadRecepcion(id: string) {
      try {
        const { data } = await getRecepcion(id);
        this.currentRecepcion = data;
      } catch (error) {
        //// console.error(error);
      }
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateRecepcion(this.$route.params.id, this.currentRecepcion);
          this.$router.push("/recepcions");
        }
      } catch (error) {
        //// console.error(error);
      }
    },

    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteRecepcion(this.$route.params.id);
          this.$router.push("/recepcions");
        }
      } catch (error) {
        //// console.error(error);
      }
    },
  },
  mounted() {
    this.loadMedicos();
    if (typeof this.$route.params.id === "string") {
      this.loadRecepcion(this.$route.params.id);
    }
  },
});
