
import numeral from 'numeral';

export default {
  props: [
    'enlaces'
  ],
  methods : {
    formatNumber(value: number) {
      return numeral(value).format("000000");
    }, 
  }
}
