
import Filters from "@/components/Recibo/FiltersComponent.vue";
import RecibosComponent from "@/components/Recibo/RecibosComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Recibo } from "@/interfaces/Recibo";
import { getRecibos } from "@/services/ReciboService";

export default {
  name: "App",

  components: {
    Filters,
    RecibosComponent,
    Navbar,
  },

  data() {
    return {
      recibos: [] as Recibo[],
      str: "",
      type: "",
    };
  },

  methods: {
    async filterRecibos(catName: string) {
      try {
        const res = await getRecibos();
        this.recibos = res.data;
      } catch (error) {
        // console.error(error);
      }
      // if (catName !== "Todos") {
      //   this.recibos = this.recibos.filter((recibo: Recibo) => {
      //     return recibo.tipo === catName;
      //   });
      // }
    },

    async search(term: string) {
      try {
        const res = await getRecibos();
        this.recibos = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.recibos = this.recibos.filter((recibo: Recibo) => {
          return recibo.medico.toLowerCase().includes(term.toLowerCase());
        });
      }
    },

    async loadRecibos() {
      try {
        const res = await getRecibos();
        this.recibos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
  },

  mounted() {
    // this.loadRecibos();
  },
};
