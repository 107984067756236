
import { defineComponent } from "vue";

export default defineComponent({
  name: "Nav-bar",

  data() {
    return {
      showUsuario: false,
      showCliente: false,
      showMedico: false,
      showReclamo: false,
      showLote: false,
      showPrefactura: false,
      showFactura: false,
      showRefactura: false,

      show: false,
      show2: false,
      showConfig: false,
      showUser: false,
      show3: false,
      str: "",

      showCont: false,
      showNCF: false,
      showGest: false,
      showEnv: false,
      showDec: false,
      showConf: false,
      showName: false,

      showGest0: false,
    };
  },

  methods: {
    tshowUsuario() {
      this.showUsuario = !this.showUsuario;
      (this.showCliente = false),
        (this.showMedico = false),
        (this.showReclamo = false),
        (this.showLote = false),
        (this.showPrefactura = false),
        (this.showFactura = false),
        (this.showRefactura = false);
    },

    tshowCliente() {
      (this.showUsuario = false),
        (this.showCliente = !this.showCliente),
        (this.showMedico = false),
        (this.showReclamo = false),
        (this.showLote = false),
        (this.showPrefactura = false),
        (this.showFactura = false),
        (this.showRefactura = false);
    },

    tshowMedico() {
      (this.showUsuario = false),
        (this.showCliente = false),
        (this.showMedico = !this.showMedico),
        (this.showReclamo = false),
        (this.showLote = false),
        (this.showPrefactura = false),
        (this.showFactura = false),
        (this.showRefactura = false);
    },

    tshowReclamo() {
      (this.showUsuario = false),
        (this.showCliente = false),
        (this.showMedico = false),
        (this.showReclamo = !this.showReclamo),
        (this.showLote = false),
        (this.showPrefactura = false),
        (this.showFactura = false),
        (this.showRefactura = false);
    },

    tshowLote() {
      (this.showUsuario = false),
        (this.showCliente = false),
        (this.showMedico = false),
        (this.showReclamo = false),
        (this.showLote = !this.showLote),
        (this.showPrefactura = false),
        (this.showFactura = false),
        (this.showRefactura = false);
    },

    tshowPrefactura() {
      (this.showUsuario = false),
        (this.showCliente = false),
        (this.showMedico = false),
        (this.showReclamo = false),
        (this.showLote = false),
        (this.showPrefactura = !this.showPrefactura),
        (this.showFactura = false),
        (this.showRefactura = false);
    },

    tshowFactura() {
      (this.showUsuario = false),
        (this.showCliente = false),
        (this.showMedico = false),
        (this.showReclamo = false),
        (this.showLote = false),
        (this.showPrefactura = false),
        (this.showFactura = !this.showFactura),
        (this.showRefactura = false);
    },

    tshowRefactura() {
      (this.showUsuario = false),
        (this.showCliente = false),
        (this.showMedico = false),
        (this.showReclamo = false),
        (this.showLote = false),
        (this.showPrefactura = false),
        (this.showFactura = false),
        (this.showRefactura = !this.showRefactura);
    },

    // ----------------------------- Old -----------------------------
    showCont2() {
      this.showCont = !this.showCont;
      this.showNCF = false;
      this.showGest = false;
      this.showEnv = false;
      this.showDec = false;
      this.showConf = false;
      this.showName = false;
    },
    showNCF2() {
      this.showCont = false;
      this.showNCF = !this.showNCF;
      this.showGest = false;
      this.showEnv = false;
      this.showDec = false;
      this.showConf = false;
      this.showName = false;
    },
    showGest2() {
      this.showCont = false;
      this.showNCF = false;
      this.showGest = !this.showGest;
      this.showEnv = false;
      this.showDec = false;
      this.showConf = false;
      this.showName = false;
    },
    showEnv2() {
      this.showCont = false;
      this.showNCF = false;
      this.showGest = false;
      this.showEnv = !this.showEnv;
      this.showDec = false;
      this.showConf = false;
      this.showName = false;
    },
    showDec2() {
      this.showCont = false;
      this.showNCF = false;
      this.showGest = false;
      this.showEnv = false;
      this.showDec = !this.showDec;
      this.showConf = false;
      this.showName = false;
    },
    showConf2() {
      this.showCont = false;
      this.showNCF = false;
      this.showGest = false;
      this.showEnv = false;
      this.showDec = false;
      this.showConf = !this.showConf;
      this.showName = false;
    },
    showName2() {
      this.showCont = false;
      this.showNCF = false;
      this.showGest = false;
      this.showEnv = false;
      this.showDec = false;
      this.showConf = false;
      this.showName = !this.showName;
    },

    showDropDownUser() {
      this.showUser = !this.showUser;
      this.show = false;
      this.show3 = false;
    },
    showDropDownConfig() {
      this.showConfig = !this.showConfig;
      this.show = false;
      this.show3 = false;
      this.showUser = false;
    },
    showDropDown() {
      this.show = !this.show;
      this.showConfig = false;
      this.show3 = false;
      this.showUser = false;
    },
    showDropDown2() {
      this.show3 = !this.show3;
      this.showConfig = false;
      this.show = false;
      this.showUser = false;
    },
    showMenu() {
      this.show2 = !this.show2;
    },
    salir() {
      this.$router.push("/");
      this.$store.dispatch("logoutAction");
    },
  },
});
