
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Documento } from "@/interfaces/Documento";
import {
  createDocumento,
  eliminateDocumentos,
  createDocumentoa,
  getOneDocumento,
} from "@/services/DocumentoService";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";
import numeral from "numeral";
import moment from "moment";
import { getDocumentosFac } from "@/services/DocumentoService";

export default defineComponent({
  name: "documentos-form",
  components: {
    Navbar,
  },
  data() {
    return {
      facturas: [] as Documento[],
      cargando: false,
      documento: {} as Documento,
      oneDocumento: {} as Documento,
      one: {},
      nextNo: Number,
      medicos: [] as Medico[],
      medicoSelected: [],
      clientes: [] as Cliente[],
      clienteSelected: [],
      descripcionCliente: "",
    };
  },

  mounted() {
    this.loadMedicos();
    this.loadClientes();
    this.loadFacturas();
    this.defFields();
    this.focus();
  },

  methods: {
    calcDocumento() {
      (this.documento.retension = this.documento.bruto * 0.1),
        (this.documento.neto = this.documento.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue);
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    defFields() {
      this.documento.fechaProceso = this.formatDate(new Date());
      this.documento.fechaEfectivo = this.formatDate(new Date());
      this.documento.fechaVence = this.formatDatePlus(new Date());
      this.documento.fechaNCF = this.formatDate(new Date());
      this.documento.descServicios = "Honorarios Profesionales";
      this.documento.tipo = "Factura";
      this.documento.subTipo = "Factura";
      this.documento.cantFactura = 1;
    },

    selectMedico(term: string) {
      this.medicoSelected = this.medicos.filter((medico: Medico) => {
        return medico.emisor.toLowerCase().includes(term.toLowerCase());
      });
      this.documento.rncMedico = this.medicoSelected[0].rncEmisor;
      this.documento.direccion = this.medicoSelected[0].direccionEmisor;
    },

    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    selectCliente(term: string) {
      this.clienteSelected = this.clientes.filter((cliente: Cliente) => {
        return cliente.descripcion.toLowerCase().includes(term.toLowerCase());
      });
      this.documento.rncCliente = this.clienteSelected[0].rnc;
      this.documento.razonCliente = this.clienteSelected[0].razonSocial;
    },
    async loadClientes() {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    async loadFacturas() {
      try {
        const res = await getDocumentosFac();
        this.facturas = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadOneDocumento() {
      try {
        const res = await getOneDocumento();
        this.oneDocumento = res.data;
        this.one = this.oneDocumento[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        this.documento.no = this.nextNo;
        this.documento.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveDocumentoa() {
      await this.loadOneDocumento();
      try {
        const res = await createDocumentoa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveDocumento() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneDocumento();
          this.oneDocumento = res.data;
          this.one = this.oneDocumento[0];
          this.nextNo = this.one.no + 1;
          this.documento.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.documento.userReg = this.$store.state.user.usuario;
        // ---------------------------------Asignando NCF---------------------------------
        this.documento.literal =
          this.clienteSelected[0].tipoComprobante.substring(0, 3);
        if (this.documento.literal == "B15") {
          this.documento.ncf = this.medicoSelected[0].compGuberAsig;
          this.documento.vencimiento = this.medicoSelected[0].fechGuberAsig;
        } else {
          this.documento.ncf = this.medicoSelected[0].compNoGuberAsig;
          this.documento.vencimiento = this.medicoSelected[0].fechNoGuberAsig;
        }
        // ---------------------------------*************---------------------------------
        const res = await createDocumento(this.documento);
        // this.$router.push("/documentos/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      await this.definingFields();
      await this.defFields();
      document.getElementById("cliente").focus();
    },

    async deleteAllDocumentos() {
      try {
        const res = await eliminateDocumentos(this.documento);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      this.documento.descCliente = "";
      this.documento.factura = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("medico").focus();
    },
  },
});
