
import Filters from "@/components/Factura/FiltersComponent.vue";
import FacturasComponent from "@/components/Factura/FacturasComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Factura } from "@/interfaces/Factura";
// import { Medico } from "@/interfaces/Medico";
import { getFacturas } from "@/services/FacturaService";
// import { getMedicos } from "@/services/MedicoService";

export default {
  name: "App",

  components: {
    Filters,
    FacturasComponent,
    Navbar,
  },

  data() {
    return {
      facturas: [] as Factura[],
      // medicos: [] as Medico[],
      // medico: "Gerson Manuel Cuevas Gómez",
      str: "",
      type: "",
    };
  },

  methods: {
    async filterFacturas(catName: string) {
      try {
        const res = await getFacturas();
        this.facturas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // if (catName !== "Todos") {
      //   this.facturas = this.facturas.filter((factura: Factura) => {
      //     return factura.tipo === catName;
      //   });
      // }
    },

    // compare(a: any, b: any) {
    //   return a.no > b.no;
    // },

    async search(term: any) {
      try {
        const res = await getFacturas();
        this.facturas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.facturas = this.facturas.filter((factura: Factura) => {
          return factura.emisor.toLowerCase().includes(term.toLowerCase());
        });
      }
    },

    async loadFacturas() {
      try {
        const res = await getFacturas();
        this.facturas = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    // async loadMedicos() {
    //   try {
    //     const res = await getMedicos();
    //     this.medicos = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    // },
  },

  mounted() {
    // this.loadFacturas();
    // this.loadMedicos();
    
  },
};
