import { Documento } from "@/interfaces/Documento";
import { AxiosResponse } from "axios";
import axios from "./axios";

export const getCuentasCobrar = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/cuentasporcobrar");

export const getDocumentos = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/get");

export const getDocumentosRep = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/getrep");

export const getDocumentosRep2 = async (): Promise<AxiosResponse<
  Documento[]
>> => await axios.get("/documentos/getrep2");

export const getDocumentosRep3 = async (): Promise<AxiosResponse<
  Documento[]
>> => await axios.get("/documentos/getrep3");

export const getDocumento = async (
  id: string
): Promise<AxiosResponse<Documento>> =>
  await axios.get(`/documentos/GetById/${id}`);

export const getDocumento2 = async (
  id: string
): Promise<AxiosResponse<Documento>> =>
  await axios.get(`/documentos/GetById2/${id}`);

export const getOneDocumento = async (): Promise<AxiosResponse<Documento>> =>
  await axios.get("/documentos/GetOne");

export const createDocumento = async (
  documento: Documento
): Promise<AxiosResponse> => await axios.post("/documentos/Save", documento);

export const createDocumentoa = async (
  documento: Documento
): Promise<AxiosResponse> => await axios.post("/documentos/Savea", documento);

export const eliminateDocumentos = async (
  documento: Documento
): Promise<AxiosResponse> => await axios.post("/documentos/delete", documento);

//----------------------------Obtención por Tipos de Documentos----------------------------

export const getDocumentosPre = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/getpre");

export const getDocumentosFac = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/getfac");

export const getDocumentosRec = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/getrec");

export const getDocumentosRecbyMed = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/getrecbymed", documento);

export const getDocumentosLot = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/getlot");

export const getDocumentosRecafil2 = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/Getrecafil", documento);

export const getReclamoslote = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/Getreclamoslote", documento);

export const getDocumentosReFac = async (): Promise<AxiosResponse<
  Documento[]
>> => await axios.get("/documentos/getrefac");

export const getDocumentosNd = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/getnd");

export const getDocumentosNc = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/getnc");

export const getDocumentosNp = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/getnp");

export const getDocumentosRe = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/getre");

export const getDocumentosFacInt = async (): Promise<AxiosResponse<
  Documento[]
>> => await axios.get("/documentos/getfacInt");

// -----------------------------------------------------------------------------------------

export const updateDocumento = async (
  id: string,
  newDocumento: Documento
): Promise<AxiosResponse<Documento>> =>
  await axios.put(`/documentos/Update/${id}`, newDocumento);

export const getDocumentosRecafil = async (
  documento: Documento
): Promise<AxiosResponse<Documento>> =>
  await axios.post("/documentos/Getrecafil", documento);

export const getDocumentoRecByAut = async (
  documento: Documento
): Promise<AxiosResponse<Documento>> =>
  await axios.post("/documentos/getDocumentoRecByAut", documento);

export const getReclamosLot = async (
  documento: Documento
): Promise<AxiosResponse<Documento>> =>
  await axios.post("/documentos/Getreclot", documento);

export const getLotes = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/Getreclot2");

export const getdigitacion = async (): Promise<AxiosResponse<Documento[]>> =>
  await axios.get("/documentos/Getmonitor");

export const deleteDocumento = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/documentos/Delete/${id}`);

// vista de Médicos ----------------------------------
export const getfacsbymed = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/getfacsbymed", documento);

export const getBalanceCliente = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/getfacsbymedgrouped", documento);

export const getprefacsbymed = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/getprefacsbymed", documento);

// -------------------------------------------------- Diviciones Por Servicio Lote --------------------------------------------------

export const getReclamosloteconsults = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/Getconsults", documento);

export const getReclamosloteproced = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/Getproced", documento);

export const getReclamoslotecirug = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/Getcirug", documento);

export const getReclamoslotehospi = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/Gethospi", documento);

export const getReclamosloteemerg = async (
  documento: Documento
): Promise<AxiosResponse> =>
  await axios.post("/documentos/Getemerg", documento);
