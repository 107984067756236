
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Cliente } from "@/interfaces/Cliente";
import {
  createCliente,
  eliminateClientes,
  createClientea,
} from "@/services/ClienteService";
import { getOneCliente } from "@/services/ClienteService";

export default defineComponent({
  name: "clientes-form",
  components: {
    Navbar,
  },
  data() {
    return {
      cliente: {} as Cliente,
      oneCliente: {} as Cliente,
      one: {},
      nextNo: Number,
    };
  },

  mounted() {
    // this.loadOneCliente();
  },

  methods: {
    async loadOneCliente() {
      try {
        const res = await getOneCliente();
        this.oneCliente = res.data;
        this.one = this.oneCliente[0];
        this.nextNo = this.one.no + 1;
        this.cliente.no = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveClientea() {
      try {
        const res = await createClientea(this.servicio);
        // console.log(res);
      } catch (error) {
        // console.error(error);
      }
    },

    async saveCliente() {
      try {
        try {
          const res = await getOneCliente();
          this.oneCliente = res.data;
          this.one = this.oneCliente[0];
          this.nextNo = this.one.no + 1;
          this.cliente.no = this.nextNo;
        } catch (error) {
          // console.error(error);
        }
        const res = await createCliente(this.cliente);
        // console.log(res);
        this.definingFields();
        this.focus();
      } catch (error) {
        // console.error(error);
      }
    },

    async deleteAllClientes() {
      try {
        const res = await eliminateClientes(this.cliente);
        // console.log(res);
      } catch (error) {
        // console.error(error);
      }
    },

    definingFields() {
      this.cliente.no = "";
      this.cliente.tipo = "";
      this.cliente.rnc = "";
      this.cliente.razonSocial = "";
      this.cliente.nombre = "";
      this.cliente.descripcion = "";
      this.cliente.direccion = "";
      this.cliente.tipoPersona = "";
      this.cliente.tipoComprobante = "";
      this.cliente.telefonos = "";
      this.cliente.email = "";
      this.cliente.observaciones = "";
      this.cliente.estatus = "";
    },

    focus() {
      document.getElementById("tipo").focus();
    },
  },
});
