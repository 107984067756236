
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Documento } from "@/interfaces/Documento";
import { Medico } from "@/interfaces/Medico";
import { getMedicos, updateMedico } from "@/services/MedicoService";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";
import {
  deleteDocumento,
  getDocumento,
  updateDocumento,
  getOneDocumento,
  createDocumento,
} from "@/services/DocumentoService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "documento-list",
  components: {
    Navbar,
  },
  data() {
    return {
      showRecibo2: false,
      viaPagar: "Transferencia",
      valorPagar: 0,
      cargando: false,
      clienteSelected: [],
      oneDocumento: {} as Documento,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      documento: {} as Documento,
      documento2: {} as Documento,
      documento3: {} as Documento,
      estado: {} as object,
      medicos: [] as Medico[],
      clientes: [] as Cliente[],
    };
  },
  methods: {
    showRecibo() {
      this.showRecibo2 = !this.showRecibo2;
    },
    async saveDocumento() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneDocumento();
          this.oneDocumento = res.data;
          this.one = this.oneDocumento[0];
          this.nextNo = this.one.no + 1;
          this.documento2.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.documento2.userReg = this.$store.state.user.usuario;
        this.documento2.fechaProceso = new Date();
        this.documento2.fechaNCF = new Date();
        this.documento2.medico = this.documento.medico;
        this.documento2.descCliente = this.documento.descCliente;
        this.documento2.principal = this.documento.principal;
        this.documento2.tipo = "Nota de Débito";
        this.documento2.subTipo = "Nota de Débito";
        this.documento2.literal = "B03";
        await this.selectMedico(this.documento.medico);
        // ---------------------------------Asignando NCF---------------------------------
        this.documento2.ncf = this.medicoSelected[0].compNdAsig;
        this.medicoSelected[0].compNdAsig += 1;
        await updateMedico(this.medicoSelected[0]._id, this.medicoSelected[0]);
        // ---------------------------------*************---------------------------------
        this.documento2.cantDeb = 1;
        this.documento2.debito = 0;
        this.documento2.credito = 0;
        this.documento2.literalAfecta = this.documento.literal;
        this.documento2.ncfAfecta = this.documento.ncf;

        const res = await createDocumento(this.documento2);
        // this.$router.push("/documentos/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      // await this.definingFields();
      // await this.defFields();
      // document.getElementById("cliente").focus();
      this.$router.push("/documentosfac");
    },

    async saveDocumento2() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneDocumento();
          this.oneDocumento = res.data;
          this.one = this.oneDocumento[0];
          this.nextNo = this.one.no + 1;
          this.documento2.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.documento2.userReg = this.$store.state.user.usuario;
        this.documento2.fechaProceso = new Date();
        this.documento2.fechaNCF = new Date();
        this.documento2.medico = this.documento.medico;
        this.documento2.descCliente = this.documento.descCliente;
        this.documento2.principal = this.documento.principal;
        this.documento2.tipo = "Nota de Crédito";
        this.documento2.subTipo = "Ajuste";
        this.documento2.literal = "B04";
        await this.selectMedico(this.documento.medico);
        // ---------------------------------Asignando NCF---------------------------------
        this.documento2.ncf = this.medicoSelected[0].compNcAsig;
        this.medicoSelected[0].compNcAsig += 1;
        await updateMedico(this.medicoSelected[0]._id, this.medicoSelected[0]);
        // ---------------------------------*************---------------------------------
        this.documento2.cantCre = 1;
        this.documento2.credito = 0;
        this.documento2.debito = 0;
        this.documento2.cantCreAj = 1;
        this.documento2.creditoAj = 0;
        this.documento2.literalAfecta = this.documento.literal;
        this.documento2.ncfAfecta = this.documento.ncf;

        const res = await createDocumento(this.documento2);
        // this.$router.push("/documentos/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      // await this.definingFields();
      // await this.defFields();
      // document.getElementById("cliente").focus();
      this.$router.push("/documentosfac");
    },

    async saveDocumento3() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneDocumento();
          this.oneDocumento = res.data;
          this.one = this.oneDocumento[0];
          this.nextNo = this.one.no + 1;
          this.documento3.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.documento3.userReg = this.$store.state.user.usuario;
        this.documento3.fechaProceso = new Date();
        this.documento3.fechaEfectivo = new Date();
        this.documento3.medico = this.documento.medico;
        this.documento3.descCliente = this.documento.descCliente;
        this.documento3.principal = this.documento.principal;
        this.documento3.tipo = "Recibo";
        if (this.documento.subTipo == "Refactura") {
          this.documento3.subTipo = "Reenvío";
          this.documento3.cantRereen = 1;
          this.documento3.reciboreen = this.valorPagar;
          this.documento3.retension = this.valorPagar * 0.1;
          this.documento3.neto =
            this.documento3.reciboen - this.documento3.retension;
        } else {
          this.documento3.subTipo = "Envío";
          this.documento3.cantReen = 1;
          this.documento3.reciboen = this.valorPagar;
          this.documento3.retension = this.valorPagar * 0.1;
          this.documento3.neto =
            this.documento3.reciboen - this.documento3.retension;
        }
        this.documento3.cantRe = 1;
        this.documento3.recibo = this.valorPagar;
        this.documento3.credito = 0;
        this.documento3.debito = 0;
        this.documento3.metodo = this.viaPagar;
        this.documento3.literalAfecta = this.documento.literal;
        this.documento3.ncfAfecta = this.documento.ncf;
        const res = await createDocumento(this.documento3);
        // this.$router.push("/documentos/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      // await this.definingFields();
      // await this.defFields();
      // document.getElementById("cliente").focus();
      this.$router.push("/documentosfac");
    },
    async aumentar() {
      this.saveDocumento();
    },

    async disminuir() {
      this.saveDocumento2();
    },

    async recibir() {
      this.saveDocumento3();
    },

    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    selectCliente(term: string) {
      this.clienteSelected = this.clientes.filter((cliente: Cliente) => {
        return cliente.descripcion.toLowerCase().includes(term.toLowerCase());
      });
      this.documento.rncCliente = this.clienteSelected[0].rnc;
      this.documento.razonCliente = this.clienteSelected[0].razonSocial;
    },
    selectMedico(term: string) {
      this.medicoSelected = this.medicos.filter((medico: Medico) => {
        return medico.emisor.toLowerCase().includes(term.toLowerCase());
      });
      this.documento.rncMedico = this.medicoSelected[0].rncEmisor;
      this.documento.direccion = this.medicoSelected[0].direccionEmisor;
    },

    async loadClientes() {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DD");
    },

    fixTime() {
      this.documento.fechaProceso = this.formatDate(
        this.documento.fechaProceso
      );
      this.documento.fechaEfectivo = this.formatDate(
        this.documento.fechaEfectivo
      );
      this.documento.fechaVence = this.formatDate(this.documento.fechaVence);
      this.documento.fechaNCF = this.formatDate(this.documento.fechaNCF);
      this.documento.vencimiento = this.formatDate(this.documento.vencimiento);
    },

    async loadDocumento(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getDocumento(id);
        this.documento = data;
        this.estado = this.documento.estado;
        this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },
    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.documento.userMod = this.$store.state.user.usuario;
          await updateDocumento(this.$route.params.id, this.documento);
          this.$router.push("/documentosfac");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteDocumento(this.$route.params.id);
          this.$router.push("/documentosfac");
        }
      } catch (error) {
        //console.error(error);
      }
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
  },
  mounted() {
    this.loadMedicos();
    this.loadClientes();
    if (typeof this.$route.params.id === "string") {
      this.loadDocumento(this.$route.params.id);
    }
  },
});
