
import Navbar from "@/components/Navbar.vue";
import { Balance } from "@/interfaces/Balance";
import { getBalances } from "@/services/BalanceService";
import numeral from "numeral";
export default {
  name: "DashboardView",
  components: {
    Navbar,
  },

  data() {
    return {
      cargando: false,
      // medicos: [] as Medico[],
      totales: {},
      balances: [] as Balance[],
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      recibos: [] as any,
    };
  },

  methods: {
    valorTotal() {
      this.totales.cuentas = this.balances.length;
      this.totales.facturado = this.balances.reduce(
        (accum: any, item: any) => accum + item.facturado,
        0
      );
      this.totales.pagado = this.balances.reduce(
        (accum: any, item: any) => accum + item.pagado,
        0
      );
      this.totales.balance = this.balances.reduce(
        (accum: any, item: any) => accum + item.balance,
        0
      );
    },
    // async loadMedicos() {
    //   try {
    //     const res = await getMedicos();
    //     this.medicos = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    // },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    async filterEstado(term: string) {
      this.cargando = !this.cargando;
      try {
        const res = await getBalances();
        this.balances = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.balances = this.balances.filter((balance: any) => {
        return balance._id.medico.toLowerCase().includes(term.toLowerCase());
      });
      this.valorTotal();
      this.cargando = !this.cargando;
    },
  },

  mounted() {
    this.filterEstado(this.$store.state.user.usuario);
    // // this.loadMedicos();
  },
};
