
import Filters from "@/components/Medico/FiltersComponent.vue";
import MedicosComponent from "@/components/Medico/MedicosComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";

export default {
  name: "App",

  components: {
    Filters,
    MedicosComponent,
    Navbar,
  },

  data() {
    return {
      medicos: [] as Medico[],
      str: "",
      type: "",
    };
  },

  methods: {
    async filterMedicos(catName: string) {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
      // if (catName !== "Todos") {
      //   this.medicos = this.medicos.filter((medico: Medico) => {
      //     return medico.tipo === catName;
      //   });
      // }
    },

    async search(term: string) {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.medicos = this.medicos.filter((medico: Medico) => {
        return medico.emisor.toLowerCase().includes(term.toLowerCase());
      });
    },

    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
  },

  mounted() {
    this.loadMedicos();
  },
};
