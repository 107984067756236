
import Filters from '@/components/Enlace/FiltersComponent.vue'
import EnlacesComponent from '@/components/Enlace/EnlacesComponent.vue'
import Navbar from "@/components/Navbar.vue";
import { Enlace } from "@/interfaces/Enlace";
import { getEnlaces } from "@/services/EnlaceService";

export default {
  name: 'App',

  components: {
    Filters,
    EnlacesComponent,
    Navbar,
  },

  data () {
    return {
      enlaces: [] as Enlace[],
      str: '',
      type: ''
      
    }
  },
  
  methods: {
    async filterEnlaces (catName: string) {
      try {
        const res = await getEnlaces();
        this.enlaces = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== 'Todos') {
        this.enlaces = this.enlaces.filter((enlace: Enlace) => {
          return enlace.tipo === catName
        })
      }
    },

    async search (term: string) {
      try {
        const res = await getEnlaces();
        this.enlaces = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.enlaces = this.enlaces.filter((enlace: Enlace) => {
        return enlace.nombre.toLowerCase().includes(term.toLowerCase())
      })
    },

    async loadEnlaces() {
      try {
        const res = await getEnlaces();
        this.enlaces = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
  },

  mounted() {
    this.loadEnlaces();
  },

  }

