
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Documento } from "@/interfaces/Documento";
import {
  createDocumento,
  eliminateDocumentos,
  createDocumentoa,
  getOneDocumento,
  getDocumentosRecafil,
  getReclamosLot,
  getLotes,
  updateDocumento,
} from "@/services/DocumentoService";
import { Medico } from "@/interfaces/Medico";
import { getMedicos, updateMedico } from "@/services/MedicoService";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "documentos-form",
  components: {
    Navbar,
  },
  data() {
    return {
      loteSelected: {},
      totales: {
        documentos: 0,
        documentos2: 0,
        documentos3: 0,
        reclamacion: 0,
        reclamacion2: 0,
        reclamacion3: 1000,
        sumaDocs: 0,
        retension: 0,
        neto: 0,
      },
      reclams: [],
      reclams2: [],
      loadedAfiliado: {} as any,
      cargando: false,
      documento: {} as Documento,
      oneDocumento: {} as Documento,
      one: {},
      nextNo: Number,
      medicos: [] as Medico[],
      lotes: [],
      medicoSelected: [],
      clientes: [] as Cliente[],
      clienteSelected: [],
      descripcionCliente: "",
    };
  },

  mounted() {
    this.$store.state.ids = [];
    this.documento.descServicios = "Honorarios Profesionales";
    this.loadMedicos();
    this.loadClientes();
    // this.defFields();
    this.defHasta();
    this.focus();
    // this.loadLotes();
  },

  methods: {
    defHasta() {
      let mydate = new Date();
      let firstDay = new Date(mydate.getFullYear(), mydate.getMonth(), 1);
      this.documento.fechaDesde = firstDay;
      this.documento.fechaHasta = firstDay;
      // this.documento.fechaHasta = this.formatDatePlus(
      //   this.documento.fechaDesde
      // );
    },
    async filterLotes(term: string) {
      // alert(term);
      try {
        const res = await this.loadLotes();
      } catch (error) {
        console.error(error);
      }
      if (term !== "Todos") {
        this.lotes = this.lotes.filter((lote: Documento) => {
          return lote._id.medico.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.valorTotal2();
    },

    deleteService(it: any, reclam: Documento) {
      this.reclams.splice(it, 1);
      this.reclams2.push(reclam);
      this.valorTotal();
    },

    addService(it: any, reclam: Documento) {
      this.reclams2.splice(it, 1);
      this.reclams.push(reclam);
      this.valorTotal();
    },

    valorTotal() {
      this.totales.documentos = this.reclams.length;
      this.totales.reclamacion = this.reclams.reduce(
        (accum: any, item: any) => accum + item.reclamacion,
        0
      );

      this.totales.documentos3 = this.reclams2.length;
      this.totales.reclamacion3 = this.reclams2.reduce(
        (accum: any, item: any) => accum + item.reclamacion,
        0
      );
    },

    valorTotal2() {
      this.totales.documentos2 = this.lotes.length;
      this.totales.reclamacion2 = this.lotes.reduce(
        (accum: any, item: any) => accum + item.total,
        0
      );
      this.totales.sumaDocs = this.lotes.reduce(
        (accum: any, item: any) => accum + item.count,
        0
      );
    },
    valorTotal3() {
      this.totales.documentos3 = this.reclams2.length;
      this.totales.reclamacion3 = this.reclams2.reduce(
        (accum: any, item: any) => accum + item.total,
        0
      );
      this.totales.sumaDocs = this.lotes.reduce(
        (accum: any, item: any) => accum + item.count,
        0
      );
    },

    async cargarReclamos(lote: Documento) {
      // this.totales.documentos = 0;
      this.documento.medico = lote._id.medico;
      this.documento.descCliente = lote._id.descCliente;
      // alert(this.lote);
      this.toggleLoading();
      try {
        const res = await getReclamosLot(this.documento);
        this.reclams = res.data;
        this.valorTotal();
      } catch (error) {
        // console.error(error);
      }
      this.lotes = [];
      this.totales.documentos2 = 0;

      this.toggleLoading();
    },

    async loadAfiliado() {
      // alert(this.documento);
      try {
        const res = await getDocumentosRecafil(this.documento);
        this.loadedAfiliado = res.data;
        this.documento.cedulaAfiliado = this.loadedAfiliado.cedulaAfiliado;
        this.documento.nombreAfiliado = this.loadedAfiliado.nombreAfiliado;
        this.documento.telefono = this.loadedAfiliado.telefono;
      } catch (error) {
        // console.error(error);
      }
    },
    calcDocumento() {
      (this.documento.retension = this.documento.bruto * 0.1),
        (this.documento.neto = this.documento.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue);
      return moment(out).format("D/MM/yyyy");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(4, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlusSix(dateValue: Date) {
      let out = moment(dateValue).add(45, "days");
      return moment(out).format("yyyy-MM-DD");
    },

    defFields() {
      this.documento.fechaProceso = new Date();
      // this.documento.fechaProceso = this.formatDate(new Date());
      // this.documento.fechaEfectivo = this.formatDate(new Date());
      // this.documento.fechaVence = this.formatDatePlusSix(new Date());
      // this.documento.fechaNCF = this.formatDate(new Date());
      // this.documento.descServicios = "Honorarios Profesionales";
      this.documento.tipo = "Lote";
      this.documento.subTipo = "Lote Envío";
      this.documento.cantLote = 1;
    },

    selectMedico(term: string) {
      this.medicoSelected = this.medicos.filter((medico: Medico) => {
        return medico.emisor.toLowerCase().includes(term.toLowerCase());
      });
      this.documento.rncMedico = this.medicoSelected[0].rncEmisor;
      this.documento.direccion = this.medicoSelected[0].direccionEmisor;
    },

    async loadMedicos() {
      this.toggleLoading();
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadLotes() {
      this.toggleLoading();
      try {
        const res = await getLotes();
        this.lotes = res.data;
        this.valorTotal2();
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
    selectCliente(term: string) {
      this.clienteSelected = this.clientes.filter((cliente: Cliente) => {
        return cliente.descripcion.toLowerCase().includes(term.toLowerCase());
      });
      this.documento.rncCliente = this.clienteSelected[0].rnc;
      this.documento.razonCliente = this.clienteSelected[0].razonSocial;
    },
    async loadClientes() {
      this.toggleLoading();
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadOneDocumento() {
      try {
        const res = await getOneDocumento();
        this.oneDocumento = res.data;
        this.one = this.oneDocumento[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        this.documento.no = this.nextNo;
        this.documento.principal = this.nextNo;
        // this.$store.state.ids.push(this.nextNo);
      } catch (error) {
        // console.error(error);
      }
    },

    async saveDocumentoa() {
      await this.loadOneDocumento();
      try {
        const res = await createDocumentoa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveDocumento() {
      this.$store.state.isPrefact = false;
      this.documento.fechaProceso = new Date();
      this.documento.descServicios = "Honorarios Profesionales";
      this.documento.tipo = "Lote";
      this.documento.subTipo = "Lote Envío";
      this.documento.cantLote = 1;

      this.toggleLoading();
      try {
        try {
          const res = await getOneDocumento();
          this.oneDocumento = res.data;
          this.one = this.oneDocumento[0];
          this.nextNo = this.one.no + 1;
          this.documento.no = this.nextNo;
          this.documento.principal = this.nextNo;
          // this.$store.state.ids.push(this.nextNo);
        } catch (error) {
          // // console.error(error);
        }
        this.documento.userReg = this.$store.state.user.usuario;
        this.documento.credito = 0;
        this.documento.debito = 0;
        this.documento.lote = this.totales.reclamacion;
        this.documento.cantReclamo = this.totales.documentos;
        const res = await createDocumento(this.documento);
        this.$store.state.id = [];
        this.$store.state.ids.push(res.data._id);
        // console.log(res.data._id);
        let i;
        for (i = 0; i <= this.reclams.length - 1; i++) {
          this.reclams[i].principal = this.nextNo;
          await updateDocumento(this.reclams[i]._id, this.reclams[i]);
        }
      } catch (error) {
        // // console.error(error);
      }

      // ---------------------------------------------Generar Factura ---------------------------------------------

      this.documento.fechaEfectivo = new Date();
      this.documento.fechaVence = this.formatDatePlusSix(new Date());
      this.documento.fechaNCF = new Date();
      // this.documento.descServicios = "Honorarios Profesionales";
      this.documento.tipo = "Factura";
      this.documento.subTipo = "Factura";
      this.documento.cantFactura = 1;
      this.documento.cantLote = 0;
      this.documento.lote = 0;
      this.documento.factura = this.totales.reclamacion;

      try {
        try {
          const res = await getOneDocumento();
          this.oneDocumento = res.data;
          this.one = this.oneDocumento[0];
          this.nextNo = this.one.no + 1;
          this.documento.no = this.nextNo;
          this.documento.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.documento.userReg = this.$store.state.user.usuario;
        // ---------------------------------Asignando NCF---------------------------------

        this.selectCliente(this.documento.descCliente);
        this.selectMedico(this.documento.medico);

        this.documento.literal = this.clienteSelected[0].tipoComprobante.substring(
          0,
          3
        );
        if (this.documento.literal == "B15") {
          this.documento.ncf = this.medicoSelected[0].compGuberAsig;
          this.documento.vencimiento = this.medicoSelected[0].fechGuberAsig;
        } else {
          this.documento.ncf = this.medicoSelected[0].compNoGuberAsig;
          this.documento.vencimiento = this.medicoSelected[0].fechNoGuberAsig;
        }
        // ---------------------------------*************---------------------------------
        this.documento.credito = 0;
        this.documento.debito = 0;
        const res = await createDocumento(this.documento);
        this.$store.state.ids.push(res.data._id);
        // -------------------------------Actualizando NCF-------------------------------
        if (this.documento.literal == "B15") {
          this.medicoSelected[0].compGuberAsig += 1;
        } else {
          this.medicoSelected[0].compNoGuberAsig += 1;
        }
        await updateMedico(this.medicoSelected[0]._id, this.medicoSelected[0]);
        // ---------------------------------*************---------------------------------
        // this.$router.push("/documentos/");
      } catch (error) {
        // // console.error(error);
      }

      // ---------------------------------------------***************  ---------------------------------------------

      await this.toggleLoading();
      this.$router.push(`/documentoslot/${this.$store.state.ids[0]}`);
      // await this.definingFields();
      // await this.defFields();
      // document.getElementById("codigoPSS").focus();
    },

    async saveDocumento2() {
      this.$store.state.isPrefact = true;
      this.documento.fechaProceso = new Date();
      this.documento.descServicios = "Honorarios Profesionales";
      this.documento.tipo = "Lote";
      this.documento.subTipo = "Lote Envío";
      this.documento.cantLote = 1;

      this.toggleLoading();
      try {
        try {
          const res = await getOneDocumento();
          this.oneDocumento = res.data;
          this.one = this.oneDocumento[0];
          this.nextNo = this.one.no + 1;
          this.documento.no = this.nextNo;
          this.documento.principal = this.nextNo;
          // this.$store.state.ids.push(this.nextNo);
        } catch (error) {
          // // console.error(error);
        }
        this.documento.userReg = this.$store.state.user.usuario;
        this.documento.credito = 0;
        this.documento.debito = 0;
        this.documento.lote = this.totales.reclamacion;
        this.documento.cantReclamo = this.totales.documentos;
        const res = await createDocumento(this.documento);
        this.$store.state.id = [];
        this.$store.state.ids.push(res.data._id);
        // console.log(res.data._id);
        let i;
        for (i = 0; i <= this.reclams.length - 1; i++) {
          this.reclams[i].principal = this.nextNo;
          await updateDocumento(this.reclams[i]._id, this.reclams[i]);
        }
      } catch (error) {
        // // console.error(error);
      }

      // ---------------------------------------------Generar Prefactura ---------------------------------------------

      // this.documento.fechaEfectivo = new Date();
      // this.documento.fechaVence = this.formatDatePlusSix(new Date());
      this.documento.tipo = "Factura";
      this.documento.subTipo = "Prefactura";
      this.documento.cantPrefactura = 1;
      this.documento.cantLote = 0;
      this.documento.lote = 0;
      this.documento.prefactura = this.totales.reclamacion;

      try {
        try {
          const res = await getOneDocumento();
          this.oneDocumento = res.data;
          this.one = this.oneDocumento[0];
          this.nextNo = this.one.no + 1;
          this.documento.no = this.nextNo;
          this.documento.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.documento.userReg = this.$store.state.user.usuario;
        this.documento.credito = 0;
        this.documento.debito = 0;
        const res = await createDocumento(this.documento);
        this.$store.state.ids.push(res.data._id);
        // this.$router.push("/documentos/");
      } catch (error) {
        // // console.error(error);
      }

      // ---------------------------------------------***************  ---------------------------------------------

      await this.toggleLoading();
      this.$router.push(`/documentoslot/${this.$store.state.ids[0]}`);
      // await this.definingFields();
      // await this.defFields();
      // document.getElementById("codigoPSS").focus();
    },

    async deleteAllDocumentos() {
      try {
        const res = await eliminateDocumentos(this.documento);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      // this.documento.medico = "";
      this.documento.codigoPSS = "";
      this.documento.noAfiliado = "";
      // this.documento.aseguradora = "";
      this.documento.descCliente = "";
      this.documento.fechaProceso = "";
      this.documento.autorizacion = "";
      this.documento.cedulaAfiliado = "";
      this.documento.nombreAfiliado = "";
      this.documento.telefono = "";
      this.documento.diagnostico = "";
      // this.documento.tipoServicio = "";
      // this.documento.reclamacion = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("medico").focus();
    },
  },
});
