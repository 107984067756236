
import Navbar from "@/components/Navbar.vue";
import { Medico } from "@/interfaces/Medico";
import { getFacturasRep } from "@/services/FacturaService";
import numeral from "numeral";
export default {
  name: "DashboardView",
  components: {
    Navbar,
  },

  data() {
    return {
      cargando: false,
      medicos: [] as Medico[],
      totales: {
        facturas: 0,
        total: 0,
      },
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      facturas: [] as any,
    };
  },

  methods: {
    valorTotal() {
      this.totales.facturas = this.facturas.reduce(
        (accum: any, item: any) => accum + item.count,
        0
      );
      this.totales.total = this.facturas.reduce(
        (accum: any, item: any) => accum + item.total,
        0
      );
    },
    // async loadMedicos() {
    //   try {
    //     const res = await getMedicos();
    //     this.medicos = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    // },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    async filterEstado(term: string) {
      this.cargando = !this.cargando;
      try {
        const res = await getFacturasRep();
        this.facturas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.facturas = this.facturas.filter((factura: any) => {
        return factura._id.emisor.toLowerCase().includes(term.toLowerCase());
      });
      this.valorTotal();
      this.cargando = !this.cargando;
    },
  },

  mounted() {
    this.filterEstado(this.$store.state.user.usuario);
    // this.loadMedicos();
    // this.valorTotal();
  },
};
