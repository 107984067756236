import { Factura } from "@/interfaces/Factura";
import { AxiosResponse } from "axios";
import axios from "./axios";

export const getFacturas = async (): Promise<AxiosResponse<Factura[]>> =>
  await axios.get("/facturas/get");

export const getFacturasRep = async (): Promise<AxiosResponse<Factura[]>> =>
  await axios.get("/facturas/GetRep");

export const getFacturasRep2 = async (): Promise<AxiosResponse<Factura[]>> =>
  await axios.get("/facturas/GetRep2");

export const getFacturasRep3 = async (): Promise<AxiosResponse<Factura[]>> =>
  await axios.get("/facturas/GetRep3");

export const getFactura = async (id: string): Promise<AxiosResponse<Factura>> =>
  await axios.get(`/facturas/GetById/${id}`);

export const getOneFactura = async (): Promise<AxiosResponse<Factura>> =>
  await axios.get("/facturas/GetOne");

export const createFactura = async (factura: Factura): Promise<AxiosResponse> =>
  await axios.post("/facturas/Save", factura);

export const createFacturaa = async (factura: Factura): Promise<AxiosResponse> =>
  await axios.post("/facturas/Savea", factura);

export const eliminateFacturas = async (factura: Factura): Promise<AxiosResponse> =>
  await axios.post("/facturas/delete", factura);

export const updateFactura = async (
  id: string,
  newFactura: Factura
): Promise<AxiosResponse<Factura>> => await axios.put(`/facturas/Update/${id}`, newFactura);

export const deleteFactura = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/facturas/Delete/${id}`);
