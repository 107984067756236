
import { defineComponent } from "vue";
import { Documento } from "@/interfaces/Documento";
import {
  deleteDocumento,
  getDocumento,
  updateDocumento,
} from "@/services/DocumentoService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "documento-list",
  data() {
    return {
      cargando: false,
      documento: {} as Documento,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },
    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("DD/MM/YYYY");
    },
    async loadDocumento(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getDocumento(id);
        this.documento = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/documentos/${this.documento._id}`);
      } else {
        this.$router.push(`/documentoslot/new`);
      }

      // this.$router.push(`/documentos/${this.documento._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateDocumento(this.$route.params.id, this.documento);
          this.$router.push("/documentos");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteDocumento(this.$route.params.id);
          this.$router.push("/documentos");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadDocumento(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
