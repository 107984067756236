
const filters = [
  'Todos',
  'ARS',
  'Centro',
  'Paciente',
  'Otro'
]

export default {
  props: [
    'filterMedicos',
    'search',
    'filteredMedicos',
  ],

  data () {
    return {
      filters,
      term: '',
      filters2: [] as []
    }
  },
}
