
import { defineComponent } from "vue";
import { Factura } from "@/interfaces/Factura";
import {
  deleteFactura,
  getFactura,
  updateFactura,
} from "@/services/FacturaService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "factura-list",
  data() {
    return {
      currentFactura: {} as Factura,
    };
  },
  methods: {
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },
    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("DD/MM/YYYY");
    },
    async loadFactura(id: string) {
      try {
        const { data } = await getFactura(id);
        this.currentFactura = data;
      } catch (error) {
        //console.error(error);
      }
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateFactura(this.$route.params.id, this.currentFactura);
          this.$router.push("/facturas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteFactura(this.$route.params.id);
          this.$router.push("/facturas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadFactura(this.$route.params.id);
    }
  },
});
