
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Directorio } from "@/interfaces/Directorio";
import { deleteDirectorio, getDirectorio, updateDirectorio } from "@/services/DirectorioService";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";

export default defineComponent({
  name: "directorio-list",
  components: {
    Navbar,
  },
  data() {
    return {
      currentDirectorio: {} as Directorio,
      medicos: [] as Medico[],
      clientes: [] as Cliente[],
    };
  },
  methods: {
    async loadDirectorio(id: string) {
      try {
        const { data } = await getDirectorio(id);
        this.currentDirectorio = data;
      } catch (error) {
        //console.error(error);
      }
    },

        async loadClientes() {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        //console.error(error);
      }
    },

    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        //console.error(error);
      }
    },

    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateDirectorio(this.$route.params.id, this.currentDirectorio);
          this.$router.push("/directorios");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteDirectorio(this.$route.params.id);
          this.$router.push("/directorios");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    this.loadMedicos();
    this.loadClientes();
    if (typeof this.$route.params.id === "string") {
      this.loadDirectorio(this.$route.params.id);
    }
  },
});
