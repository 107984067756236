
import Filters from "@/components/Cliente/FiltersComponent.vue";
import ClientesComponent from "@/components/Cliente/ClientesComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";

export default {
  name: "ClienteComponent",

  components: {
    Filters,
    ClientesComponent,
    Navbar,
  },

  data() {
    return {
      clientes: [] as Cliente[],
      str: "",
      type: "",
    };
  },

  methods: {
    async filterClientes(catName: string) {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.clientes = this.clientes.filter((cliente: Cliente) => {
          return cliente.tipo === catName;
        });
      }
    },

    async search(term: string) {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.clientes = this.clientes.filter((cliente: Cliente) => {
        return cliente.descripcion.toLowerCase().includes(term.toLowerCase());
      });
    },

    async loadClientes() {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
  },

  mounted() {
    this.loadClientes();
  },
};
