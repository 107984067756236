
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Usuario } from "@/interfaces/Usuario";
import {
  createUsuario,
  eliminateUsuarios,
  createUsuarioa,
} from "@/services/UsuarioService";
import { getOneUsuario } from "@/services/UsuarioService";

export default defineComponent({
  name: "usuarios-form",
  components: {
    Navbar,
  },
  data() {
    return {
      usuario: {} as Usuario,
      oneUsuario: {} as Usuario,
      one: {},
      nextNo: Number,
    };
  },

  mounted() {
    // this.loadOneUsuario();
  },

  methods: {
    async loadOneUsuario() {
      try {
        const res = await getOneUsuario();
        this.oneUsuario = res.data;
        this.one = this.oneUsuario[0];
        this.nextNo = this.one.no + 1;
        this.usuario.no = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveUsuarioa() {
      try {
        const res = await createUsuarioa(this.servicio);
        // console.log(res);
      } catch (error) {
        // console.error(error);
      }
    },

    async saveUsuario() {
      try {
        try {
          const res = await getOneUsuario();
          this.oneUsuario = res.data;
          this.one = this.oneUsuario[0];
          this.nextNo = this.one.no + 1;
          this.usuario.no = this.nextNo;
        } catch (error) {
          // console.error(error);
        }
        const res = await createUsuario(this.usuario);
        // console.log(res);
        this.definingFields();
        this.focus();
      } catch (error) {
        // console.error(error);
      }
    },

    async deleteAllUsuarios() {
      try {
        const res = await eliminateUsuarios(this.usuario);
        // console.log(res);
      } catch (error) {
        // console.error(error);
      }
    },

    definingFields() {
      this.usuario.email = "";
      this.usuario.password = "";
      this.usuario.role = "";
      this.usuario.nombre = "";
      this.usuario.estatus = "";
    },

    focus() {
      document.getElementById("nombre").focus();
    },
  },
});
